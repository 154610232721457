<template>
  <div id="answersheet">
    <div class="top">
      <p>
        西知题库<span>{{ titlename }}></span>
      </p>
    </div>
    <div class="main">
      <div class="mian-content">
        <div class="main-top">
          <img src="@/assets/image/kaoyan.png" alt="" />
          <h2>{{ title }}</h2>
        </div>

        <div class="score" v-if="question.question_class_id!=275">
          <p >{{ score }}</p>
          <p>得分</p>
        </div>
        <div v-else class="score scoreStr">
<p>{{scoreStr}}</p>
        </div>
        <p class="minter">
          本次用时<span>{{ minter }}</span
          >min
        </p>
        <div class="left-top">
          <div v-for="item in list" :key="item.id">
            <p>
              <span class="num">{{ item.errorTotal }}</span
              ><span>{{ item.num }}</span>
            </p>
            <p>{{ item.text }}</p>
          </div>
        </div>
      </div>
      <div class="Btn">
        <button @click="clickLinkAnalysis">查看解析</button>
        <button @click="clickLink">返回列表</button>
      </div>
    </div>
    <!-- <div class="bottom">
      <div class="main-bottom">
        <div class="bottom-title">
          <img src="@/assets/image/kaoyan.png" alt="" />
          <h2>试卷名{{ title }}</h2>
        </div>
        <div class="sign">
          <div v-for="(item, index) in sign" :key="index">
            <div></div>
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
      <p>【答题卡】</p>
      <ul>
        <li></li>
      </ul>
      
    </div> -->
  </div>
</template>

<script>
export default {
  head() {
    return {
      title: this.title,
    };
  },
  data() {
    return {
      title: "", //试卷名
      score: "", //得分
      minter: "", //用时
      titlename: "",
      list: [
        { id: 1, errorTotal: "0", text: "答对题数", num: "道" },
        { id: 2, errorTotal: "0", text: "答错题数", num: "道" },
        { id: 3, errorTotal: "0", text: "未答题数", num: "道" },
      ],
      sign: [
        { id: 1, name: "答对" },
        { id: 2, name: "答错" },
        { id: 3, name: "未作答" },
      ],
      scoreStr:""
    };
  },
  methods: {
    clickLinkAnalysis() {
      let _this = this;
      _this.$router.push("/Index/Analysis");
    },
    clickLink() {
      let _this = this;
      _this.$router.push("/Index/Home");
    },
  },
  created() {
    let _this = this;
    _this.loading = _this.$loading({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    _this.question = _this.$store.state.question;
    console.log( _this.question);
    _this.$api.recordtiemslist(_this.question).then((res) => {
      this.titlename = res.data.class.parent.name;
      // this.date = res.data.list.created_at;
      this.title = res.data.list.name;
      this.minter = res.data.list.durations;
      _this.list[0].errorTotal = res.data.countyes;

      _this.list[1].errorTotal = res.data.countno;
      _this.list[2].errorTotal = res.data.countnull;

      _this.score = res.data.list.grade;
      if (58.82>_this.score&&_this.score>=45.22) {
         _this.scoreStr="通过"
      }else if(60.42>_this.score&&_this.score>=52.82) {
         _this.scoreStr="良好"

      }else if(_this.score>=60.42){
         _this.scoreStr="优秀"
      }else{
         _this.scoreStr="不通过"

      }
      this.loading.close();
    });
  },
};
</script>

<style lang="less" scoped>
#answersheet {
  width: 1180px;
  margin: 0 auto;
  padding-top: 20px;
  .top {
    padding: 30px;
    background: #fff;
    p {
      span {
        color: #666;
        font-size: 14px;
        margin-left: 30px;
      }
    }
  }
  .main {
    background: #fff;
    padding: 0 100px 60px;
    margin-bottom: 10px;
    margin-top: 14px;
    .mian-content {
      text-align: center;
      padding-bottom: 20px;
      .main-top {
        padding-bottom: 60px;
        h2 {
          padding: 20px 0;
          border-bottom: 1px solid #ccc;
        }
      }

      .score {
        &:nth-child(2) {
          width: 204px;
          height: 204px;
          margin: 0 auto;
          border: 10px solid rgba(255, 56, 51, 0.8);
          border-radius: 50%;
          padding-top: 30px;
          p {
            &:first-child {
              font-size: 50px;
            }
            &:last-child {
              font-size: 18px;
              font-weight: 400;
              color: #df3833;
              margin-top: 28px;
              margin-bottom: 0 !important;
            }
          }
        }
      }
      .scoreStr{
        display: flex;
        align-items: center;
        justify-content: center;
          padding-top: 0 !important;
          p{
              margin-top: 0 !important;

          }

      }
      .minter {
        margin: 60px auto;
        width: 170px;
        background: #f9f9fb;
        color: #999;
        span {
          font-size: 24px;
          color: #df3833;
          margin-left: 10px;
          margin-right: 10px;
        }
      }
      .left-top {
        width: 675px;
        margin: auto;
        background: #fff;
        padding: 42px 0;
        display: flex;
        justify-content: space-between;
        div {
          width: 224px;
          text-align: center;
          border-right: 1px solid #ccc;
          p {
            color: #999;
            font-size: 12px;
          }
          &:nth-child(1) {
            p {
              .num {
                font-size: 46px;
                line-height: 46px;
                color: #6fd321;
              }
            }
          }
          &:nth-child(2) {
            p {
              .num {
                font-size: 46px;
                line-height: 46px;
                color: #ff6c6c;
              }
            }
          }
          &:nth-child(3) {
            p {
              .num {
                font-size: 46px;
                line-height: 46px;
                color: #ffd007;
              }
            }
          }
        }
      }
    }
    .Btn {
      text-align: center;
      margin: 50px auto 50px;

      button {
        background: #df3833;
        color: #fff;
        border: #df3833;
        padding: 5px 15px;
        margin-right: 30px;
        border-radius: 3px;
        cursor: pointer;
      }
    }
  }
}
</style>